<template>
  <MasterDetail
    v-if="showTable"
    ref="masterDetail"
    :cols="cols"
    :customResource="customResource"
    :opts="opts"
  ></MasterDetail>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import years from "@/helpers/yearsOptions";

export default {
  props: {
    selectedClient: { type: Number, default: () => null }
  },
  components: {
    MasterDetail: () => import("@/components/master-detail.vue")
  },
  computed: {
    ...mapGetters(['clientId']),
    customResource() {
      const that = this;
      const resource = this.apiResource(
        `v1/clientes/${this.selectedClient ? this.selectedClient : this.clientId}/certidoes`
      );
      return {
        ...resource,
        get() {
          return resource.get().then((result) => {
            const value = result.map((item) => {
              return {
                ...item,
                arquivo: (item.anexo && !Array.isArray(item.anexo)) ? [{ name: that.getFileNameFromUrl(item.anexo), url: item.anexo}] : item.anexo,
              }
            })

            return value;
          })
        },
        save(args) {
          const objAnexo = new FormData();
          args.anexo = args.arquivo[0];
          Object.keys(args).forEach((key) => objAnexo.append(key, args[key]))
          return resource.save(objAnexo, args.id ?? null);
        }
      };
    }
  },
  methods: {
    ...mapMutations(["setPageTableFilters"]),
    getFileNameFromUrl(url) {
      const cleanUrl = url.split('?')[0].split('#')[0];
      const segments = cleanUrl.split('/');
      return segments.pop() || '';
    }
  },
  created() {
    this.setPageTableFilters({ anoBase: null });
    this.showTable = true;
  },
  data() {
    return {
      showTable: false,
      opts: {
        years,
        tipoCertidao: [
          { id: 1, name: "Certidão negativa de débito" },
          { id: 2, name: "Certidão positiva de débito" },
          { id: 3, name: "Certidão positiva com efeito de negativa" }
        ]
      },
      cols: [
        {
          key: "id",
          name: "Código",
          sort: 0,
          hideInform: true,
          hideInTable: true
        },
        {
          key: "tipo",
          name: "Tipo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "tipoCertidao", key: "id", name: "name" }
        },
        {
          key: "emissao",
          name: "Data de emissão",
          type: this.$fieldTypes.DATE,
          colSize: 4
        },
        {
          key: "vencimento",
          name: "Data de vencimento",
          type: this.$fieldTypes.DATE,
          colSize: 4
        },
        {
          key: "anoBase",
          name: "Ano Base",
          type: this.$fieldTypes.SELECT,
          rel: { to: "years", key: "value", name: "text" },
          rules: [{ rule: "required" }],
          filterable: true,
          colSize: 4
        },
        {
          key: "arquivo",
          name: "Arquivo(s)",
          type: this.$fieldTypes.FILE2,
          hideInTable: true,
          colSize: 12
        },
        {
          key: "anexo",
          name: "Baixar",
          type: this.$fieldTypes.DOWNLOAD,
          hideInform: true,
          width: "100px"
        }
      ]
    };
  }
};
</script>
